import { Component, OnInit } from '@angular/core';
import { YoutubeService } from 'src/app/service/youtube.service';

@Component({
  selector: 'app-youtube',
  templateUrl: './youtube.component.html',
  styleUrls: ['./youtube.component.css']
})
export class YoutubeComponent implements OnInit {

  constructor(private service: YoutubeService) { }

  ngOnInit() {
    this.service.getVideosForChanel('UCYufxNN4JYBVQ7zL1Khb7sQ', 30).subscribe(p => {
      console.log(p);
    });
  }

}
