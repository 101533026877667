import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class YoutubeService {

  private apiKey = 'AIzaSyBswWwydgmHLrR9yDZY4Sa5bG0wfs95FwQ';

  constructor(private client: HttpClient) { }

  getVideosForChanel(channel, maxResults): Observable<object> {
    const url = 'https://www.googleapis.com/youtube/v3/search?key='
      + this.apiKey + '&channelId=' + channel + '&order=date&part=snippet &type=video,id&maxResults=' + maxResults;
    return this.client.get(url)
      .pipe(map((res) => {
        return res;
      }));
    }
}
