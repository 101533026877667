import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FacebookComponent } from './components/facebook/facebook.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TwitterComponent } from './components/twitter/twitter.component';
import { YoutubeComponent } from './components/youtube/youtube.component';
import { HttpClientModule } from '@angular/common/http';
@NgModule({
  declarations: [
    AppComponent,
    FacebookComponent,
    DashboardComponent,
    TwitterComponent,
    YoutubeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
